import * as actionType from './actionsTypes';
import { addCoupon, couponList, addGiftCard, giftCardList, addSpecialDeal, specialDealList } from '../services/offerService';
import { resHandle2 } from '../helper';

const initialState = {
    activeTab: 'couponCode',
    loader: false,
    loader2: false,
    error: '',
    toggleCoupanCodeModal: false,
    toggleGiftCardModal: false,
    toggleSpecialDealsModal: false,
    couponCodeList: [],
    giftCardList: [],
    specialDealList: [],
    couponCode: '',
    giftCard: '',
    specialDeal: '',
    isCouponView: false,
    viewCoupon: '',
    couponCount: 0,
    giftCardCount: 0,
    specialDealCount: 0,
    isGiftView: false,
    viewGift: '',
    isDealView: false,
    viewDeal: '',
    isNewGiftCard: false


}


/************************************************************************************/
// offer Reducer
/************************************************************************************/


const offerReducer = (state = initialState, action) => {

    switch (action.type) {

        case actionType.OFFER_LOADER:
            return {
            ...state,
            error: '',
            loader: !state.loader
        }


        case actionType.COUPAN_CODE_MODAL:
            return {
            ...state,
            viewCoupon: '',
            toggleCoupanCodeModal: !state.toggleCoupanCodeModal,
            isCouponView: false
        }

        case actionType.IS_NEW_GIFT_CARD:
            return {
            ...state,
            isNewGiftCard: !state.isNewGiftCard,
        }

        case actionType.IS_COUPON_VIEW:
            return {
            ...state,
            viewCoupon: action.payload,
            toggleCoupanCodeModal: !state.toggleCoupanCodeModal,
            isCouponView: !state.isCouponView
        }


        case actionType.IS_GIFT_VIEW:
            return {
            ...state,
            viewGift: action.payload,
            toggleGiftCardModal: !state.toggleGiftCardModal,
            isGiftView: !state.isGiftView
        }

        case actionType.IS_DEAL_VIEW:
            return {
            ...state,
            viewDeal: action.payload,
            toggleSpecialDealsModal: !state.toggleSpecialDealsModal,
            isDealView: !state.isDealView
        }


        case actionType.GIFT_CARD_MODAL:
            return {
            ...state,
            viewGift: '',
            toggleGiftCardModal: !state.toggleGiftCardModal,
            isGiftView: false

        }


        case actionType.SPECIAL_DEAL_MODAL:
            return {
            ...state,
            toggleSpecialDealsModal: !state.toggleSpecialDealsModal,
            viewDeal: '',
            isDealView: false
        }


        case actionType.COUPAN_LIST:
            return {
            ...state,
            couponCodeList: action.payload.result,
            couponCount: action.payload.count,
        }

        case actionType.COUPAN_LOADER:
            return {
            ...state,
            loader2: !state.loader2,
        }


        case actionType.GIFT_CARD_LIST:
            return {
            ...state,
            giftCardList: action.payload.result,
            giftCardCount: action.payload.count,
        }


        case actionType.SPECIAL_DEAL_LIST:
            return {
            ...state,
            specialDealList: action.payload.result,
            specialDealCount: action.payload.count,
        }

        case actionType.OFFER_ERROR:
            return {
            ...state,
            loader: false,
            error: action.payload
        }


        case actionType.OFFER_ACTIVE_TAB:
            return {
            ...state,
            activeTab: action.payload
            }

        

        default: return state;
    }
}




/************************************************************************************/
// All UI Actions
/************************************************************************************/


export const coupanCodeModalAction = () => dispatch => dispatch({ type: actionType.COUPAN_CODE_MODAL });

export const activeTabAction = payload => dispatch => dispatch({ type: actionType.OFFER_ACTIVE_TAB, payload });

export const coupanViewModalAction = payload => dispatch => dispatch({ type: actionType.IS_COUPON_VIEW, payload });

export const giftViewModalAction = payload => dispatch => dispatch({ type: actionType.IS_GIFT_VIEW, payload });

export const dealViewModalAction = payload => dispatch => dispatch({ type: actionType.IS_DEAL_VIEW, payload });

export const giftCardModalAction = () => dispatch => dispatch({ type: actionType.GIFT_CARD_MODAL });

export const specialDealsModalAction = () => dispatch => dispatch({ type: actionType.SPECIAL_DEAL_MODAL });

export const isNewGiftCardAction = () => dispatch => dispatch({ type: actionType.IS_NEW_GIFT_CARD });




export const addCouponAction = params => async dispatch => {
    dispatch({ type: actionType.OFFER_LOADER });
    try {
        let coupon = await addCoupon(params);
        let { status, data } = resHandle2(coupon);
        if(status){
            return status;
        }else{
            dispatch({ type: actionType.OFFER_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.OFFER_ERROR, payload: error });
    }
}



export const addGiftCardAction = params => async dispatch => {
    dispatch({ type: actionType.OFFER_LOADER });
    try {
        let giftcard = await addGiftCard(params);
        let { status, data } = resHandle2(giftcard);
        if(status){
            return status;
        }else{
            dispatch({ type: actionType.OFFER_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.OFFER_ERROR, payload: error });
    }
}



export const addSpecialDealAction = params => async dispatch => {
    dispatch({ type: actionType.OFFER_LOADER });
    try {
        let specialDeal = await addSpecialDeal(params);
        let { status, data } = resHandle2(specialDeal);
        if(status){
            return status;
        }else{
            dispatch({ type: actionType.OFFER_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.OFFER_ERROR, payload: error });
    }
}




export const couponListAction = params => async dispatch => {
    dispatch({ type: actionType.OFFER_LOADER });
    try {
        dispatch({ type: actionType.COUPAN_LOADER });
        let coupon = await couponList(params);
        let { status, data } = resHandle2(coupon);
        dispatch({ type: actionType.COUPAN_LOADER });
        if(status){
            dispatch({ type: actionType.COUPAN_LIST,  payload: coupon.data.responseData });
            return status;
        }else{
            dispatch({ type: actionType.OFFER_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.OFFER_ERROR, payload: error });
    }
}


export const giftCardListAction = params => async dispatch => {
    dispatch({ type: actionType.OFFER_LOADER });
    try {
        let giftCard = await giftCardList(params);
        let { status, data } = resHandle2(giftCard);
        
        if(status){
            dispatch({ type: actionType.GIFT_CARD_LIST,  payload: giftCard.data.responseData });
            return status;
        }else{
            dispatch({ type: actionType.OFFER_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.OFFER_ERROR, payload: error });
    }
}




export const specialDealListAction = params => async dispatch => {
    dispatch({ type: actionType.OFFER_LOADER });
    try {
        let specialDeal = await specialDealList(params);
        let { status, data } = resHandle2(specialDeal);
        if(status){
            dispatch({ type: actionType.SPECIAL_DEAL_LIST,  payload: specialDeal.data.responseData });
            return status;
        }else{
            dispatch({ type: actionType.OFFER_ERROR, payload: data });
        }

    } catch (error) {
       dispatch({ type: actionType.OFFER_ERROR, payload: error });
    }
}






export default offerReducer;