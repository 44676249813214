import moment from 'moment';
import InputMask from 'react-input-mask';
import React, { useEffect, useState, Fragment } from 'react';
import { notification, DatePicker, Select } from 'antd';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { createComponent } from '@lit-labs/react';
import { EventParticipationCancellationInsurance, createTheme } from "@vertical-insure/web-components/legacy/index.js";
import { validateCouponCodeService } from '../../services/offerService';
import { billingDetailService, getCustomerCardsService } from '../../services/userService';
import { createBookingByAdminService, getCampDetailService, createCustomerCardService, calcAllPriceService } from '../../services/bookingService';
import { calculateEMIPaymentDates, resHandle } from '../../helper';
import { InformationIcon } from '../reuse/SVG';
import _ from "lodash";
import { AES } from 'crypto-js';
import settings from '../../config/settings';

const addZero = num => {
    if( num.toString().length < 2 )
    return "0" + num;
    return num.toString();
}


const { Option } = Select;

const getCardTypeName = type => {
    switch (type) {
        case 'American Express':
            return 'AmericanExpress';
            break;
        case 'Visa Electron':
        case 'visa':
            return 'Visa';
            break;
        case 'mastercard':
        case 'Mastercard':
            return 'MasterCard';
            break;
        case 'discover':
        case 'Discover':
            return 'Discover';
            break;
        case 'china_unionpay':
        case 'ChinaUnionpay':
            return 'Unionpay';
            break;
        case 'Diners Club':
            return 'DinersClub';
            break;
        default:
            return 'Visa';
    }
}


const getWithTaxPrice = (obj, amount, _insuranceTotal) => {
    let _amount = (amount + _insuranceTotal);
    let withTax = 0
    if(obj.percentage){
        withTax = _amount + ((_amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        withTax = _amount + obj.taxRate;
    }
 
    return withTax.toFixed(2);
}

const getSplitPrice = (obj, amount, _insuranceTotal) => {
    let _amount = amount / (obj.noOfTimesPayment || 1);
    let withTax = 0
    if(obj.percentage){
        withTax = _amount + ((_amount * obj.percentage) / 100) + obj.taxRate;
    }else{
        withTax = _amount + _insuranceTotal;
    }
 
    return withTax.toFixed(2);
}





const InsuranceComponent = createComponent({
    tagName: "event-participation-cancellation-insurance",
    elementClass: EventParticipationCancellationInsurance,
    react: React,
    events: {
      onQuoteChange: "quote-change",
      onQuoteStateChange: "offer-state-change"
    }
  });
  
  const InsuranceComponentMemo = React.memo(function Component(props) {
    const {acceptedInsuranceQuotes, insuranceTotal, ...insuranceProps} = props;
    return <InsuranceComponent {...insuranceProps} />;
  }, (prevProps, nextProps) => {
      let prevQuotes = Object.values(prevProps.acceptedInsuranceQuotes).map(q => {
          const {checkout_url, ...quote} = q.quote;
          return quote;
      });

      let nextQuotes = Object.values(nextProps.acceptedInsuranceQuotes).map(q => {
          const {checkout_url, ...quote} = q.quote;
          return quote;
      });

      return _.isEqual(Object.keys(prevProps.acceptedInsuranceQuotes), Object.keys(nextProps.acceptedInsuranceQuotes))
        &&  _.isEqual(prevQuotes, nextQuotes)
        && prevProps.insuranceTotal === nextProps.insuranceTotal;
  });
  
  const insuranceComponentTheme = createTheme({
    colors: {
      primary: '#842014',
      secondary: '#F9A720'
    },
    fonts: {
      main: 'kohinoor, sans-serif',
      promotional: 'kohinoor, sans-serif',
      title: 'kohinoor, sans-serif',
      titleSize: '0.98rem',
      promotionalSize: '0.98rem'
    }
  });



  const getSplitPrice2 = (obj, amount) => {
    let _amount = amount / (obj.noOfTimesPayment || 1);
    return _amount.toFixed(2);
}



const PaymentInformationCompoant = ({programs, userId, handleBack, userEmail, user_data}) => {
    const history = useHistory();
    const [allPrograms, setAllPrograms] = useState(programs);
    const [couponCode, setCouponCode] = useState('');
    const [couponCodeLoader, setCouponCodeLoader] = useState(false);
    const [couponCodeErr, setCouponCodeErr] = useState('');
    const [couponCodeSuccess, setCouponCodeSuccess] = useState('');
    const [couponMoney, setCouponMoney] = useState(0);

    const [subtotal, setSubtotal] = useState(0);
    const [totalToPay, setTotalToPay] = useState(0);

    const [paymentMethod, setPaymentMethod] = useState(1);
    const [isNewCard, setIsNewCard] = useState(false);
    const [cardList, setCardList] = useState([]);
    const [customerProfileId, setCustomerProfileId] = useState([]);
    const [customerPaymentProfileId, setCustomerPaymentProfileId] = useState('');
    const [customerPaymentProfileIdError, setCustomerPaymentProfileIdError] = useState('');
    const [billingDetail, setBillingDetail] = useState({});
    const [resErr, setResErr] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [discountArr, setDiscountArr] = useState([]);

    const [allCampCodes, setAllCampCodes] = useState('');


    const [creditCardRate, setCreditCardRate] = useState({});
    const [achTransferRate, setAchTransferRate] = useState({});
    const [byCheque, setByCheque] = useState({});
    const [splitPaymentRate, setSplitPaymentRate] = useState({});



    const [paymentMethodErr, setPaymentMethodErr] = useState('');


    const [addCardBtnLoader, setAddCardBtnLoader] = useState(false);
    const [cardNo, setCardNo] = useState('');
    const [cardNoErr, setCardNoErr] = useState('');
    const [expDate, setExpDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [expDateErr, setExpDateErr] = useState('');
    const [cvc, setCvc] = useState('');
    const [cvcErr, setCvcErr] = useState('');
    const [firstName, setFirstName] = useState('');
    const [firstNameErr, setFirstNameErr] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [zipCodeErr, setZipCodeErr] = useState('');


    const [bankAccountType, setBankAccountType] = useState('');
    const [bankAccountNum, setBankAccountNum] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [nameOnAccount, setNameOnAccount] = useState('');
    const [bankAccountTypeErr, setBankAccountTypeErr] = useState('');
    const [bankAccountNumErr, setBankAccountNumErr] = useState('');
    const [routingNumberErr, setRoutingNumberErr] = useState('');
    const [nameOnAccountErr, setNameOnAccountErr] = useState('');

    const [insuranceTotal, setInsuranceTotal] = useState(0);
    const [acceptedInsuranceQuotes, setAcceptedInsuranceQuotes] = useState({});




    const calcAllPriceFun = () => {
        if(programs.length){
            let _programs = programs.map(i => ({
                programId: i._id,
                campCode: i.campCode,
                selectedDates: i.selectedDates || [],
                campers: i.campers.map(c => ({
                    isCommuter: c.isCommuter,
                    additionalExperience: c.additionalExperience.filter(j => j.numberOfBooking),
                    answers: c.answers,
                }))
            }))

            let params = {
                'programs': _programs
            };


            calcAllPriceService(params).then(res => {
                if(res?.data?.statusCode){
                    let res_programs = res?.data?.responseData?.programs;

                    let _allPrograms = [...programs];
                    let finalPrograms = [];

                    for (let i = 0; i < res_programs.length; i++) {
                        let obj = _allPrograms.find(kk => kk.programId == res_programs[i].programId);
                        let newObj = Object.assign({}, res_programs[i], obj) 
                        finalPrograms.push(newObj);
                    }



                    let _totalCost = 0;
                    let _allCampCodes = [];
                    let checkSlots = []
                    setEmail(finalPrograms);
                    for (let i = 0; i < finalPrograms.length; i++) {
                        _allCampCodes.push(finalPrograms[i].campCode);
                        checkSlots.push({
                            programId: finalPrograms[i].programId,
                            selectedDates: finalPrograms[i].selectedDates || [],
                            noOfBookings: finalPrograms[i].campers.length        
                        })

                        _totalCost = _totalCost + finalPrograms[i].f_totalPrice;

                        let _product_prices = 0;
                        for (let j = 0; j < finalPrograms[i].campers.length; j++) {
                            for (let k = 0; k < finalPrograms[i].campers[j].selectedProduct.length; k++) {
                                _product_prices += (finalPrograms[i].campers[j].selectedProduct[k].count * finalPrograms[i].campers[j].selectedProduct[k].price)
                            }

                        }

                        if(_product_prices){
                            _totalCost += _product_prices;
                        }

                        
                    }
                    setAllCampCodes(_allCampCodes);
                    setSubtotal(_totalCost);
                    setTotalToPay(_totalCost);


                    setAllPrograms(finalPrograms)
                }
            })


        }

       

    }

    useEffect(() => {
        if(programs.length){
            calcAllPriceFun();
        }
    }, [programs])





    const getTaxs = () => {
        getCampDetailService(`?campCode=${allCampCodes[0]}`).then(res => {
            if(res?.data?.statusCode){
              let _data = res.data.responseData.result;
              setCreditCardRate(_data.creditCardRate || {});
              setAchTransferRate(_data.achTransferRate || {});
              setByCheque(_data.byCheque || {});
              setSplitPaymentRate(_data.splitPaymentRate || {});
            }
        })
    }


    useEffect(() => {
        if(allCampCodes.length){
            getTaxs();
        }
      
    }, [allCampCodes])




    const handleCouponCodeSubmit = () => {
        if(couponCode){
            setCouponCodeLoader(true);
            let noOfParticepentCount = 0;

            let newProgramsArr = [];


            for (let i = 0; i < allPrograms.length; i++) {
                for (let j = 0; j < allPrograms[i].campers.length; j++) {
                    noOfParticepentCount = noOfParticepentCount + 1
                }
            }

            for (let i = 0; i < allPrograms.length; i++) {
                let newCampers = 0
                for (let j = 0; j < allPrograms[i].campers.length; j++) {
                    newCampers = newCampers + 1
                }

                newProgramsArr.push({
                    programId: allPrograms[i].programId,
                    programCost: allPrograms[i].f_campRegistrationFee + allPrograms[i].f_taxPrice,
                })
            
            }



            let programId = JSON.stringify(allPrograms.map(i => i._id));

            let parms = {
                couponCode: couponCode,
                programId: programId,
                noOfParticepentCount: noOfParticepentCount,
                programData: JSON.stringify(newProgramsArr)
             }


            validateCouponCodeService(parms).then(res => {
                setCouponCodeLoader(false);
                if (res?.data?.responseData?.code == 1) {
                    let discountMoney = res.data.responseData.discountMoney;
                    let _discountArr = res.data.responseData.discountArr || [];
                    setCouponMoney(discountMoney);
                    setDiscountArr(_discountArr);
                    let _totalToPay = subtotal - discountMoney;
                    if (discountMoney > _totalToPay) {
                        _totalToPay = 0;
                        setCouponMoney(subtotal);
                    }
                    setTotalToPay(_totalToPay);
                    setCouponCodeSuccess('Coupon Applied Successfully')
                }else{
                    setCouponCodeErr('Invalid coupon Code');
                    setCouponCodeSuccess('');
                }
            })
        }else{
            setCouponCodeErr('Please enter valid coupon code');
            setCouponCodeSuccess('');
        }

    }

    const getCards = () => {
        let query = `?userId=${userId}&isWeb=1&campCode=${allCampCodes[0]}`
        getCustomerCardsService(query).then(res => {
            if (res.data.statusCode) {
                setCardList(res.data.responseData.result ? res.data.responseData.result : []);
                if(res.data.responseData.length){
                    setCustomerProfileId(res.data.responseData.result ? res.data.responseData.result[0].card.signature : '');
                }
               
            } else {
                setCardList([]);
            }
        })
    }



    useEffect(() => {
        if(allCampCodes.length){
            getCards();
        }
       
    }, [allCampCodes])


    const getBillingDetail = () => {
        let query = `?userId=${userId}`;
        billingDetailService(query).then(res => {
            if (res.data.statusCode) {
                setBillingDetail(res.data.responseData.billingDetail)
            }
        })
    }


    useEffect(() => {
        if(userId){
            getBillingDetail()
        }
    }, [userId])


    const handleValidate = () => {
        let validate = true;

        if(totalToPay > 0){
            if (paymentMethod == 1) {
                // if (customerProfileId == '') {
                //     setResErr('Please select card');
                //     validate = false
                // }
    
                if (customerPaymentProfileId == '') {
                    setResErr('Please select card');
                    validate = false
                }
            }
        }

        return validate;
    }


    const handleCreateBooking = () => {

        if (handleValidate()) {

            setBtnLoader(true);
            let payment = {};
            let params = {};
        
            if (paymentMethod == 1) {
                payment.method = 'Credit Card';
                payment.amount = getWithTaxPrice(creditCardRate, totalToPay, 0);
     
                params.authPayment = JSON.stringify({
                    paymentType: paymentMethod,
                    customerProfileId,
                    customerPaymentProfileId,
                    token: customerPaymentProfileId
                })
            }


            if(paymentMethod == 3) {
                payment.method = 'Internet Banking';
                payment.amount = getWithTaxPrice(achTransferRate, totalToPay, 0);


                const __routing_number = AES.encrypt(routingNumber, settings.api.mySecretKey).toString();
                const __bankAccountNum = AES.encrypt(bankAccountNum, settings.api.mySecretKey).toString();


                params.internetBanking = 1;
                params.authPayment = JSON.stringify({
                    account_owner_name: nameOnAccount,
                    routing_number: __routing_number,
                    account_number: __bankAccountNum,
                    account_type: bankAccountType,
                    account_owner_type: 'individual',
                    country: 'US',
                    currency: 'usd',
                    paymentType: 4
                });
            }

            if(paymentMethod == 4) {
                payment.method = 'Check';
                payment.amount = getWithTaxPrice(byCheque, totalToPay, 0);
                payment.status = 2;

                params.authPayment = JSON.stringify({
                    paymentType: 5
                });
            }

            if(paymentMethod == 5) {
                payment.method = 'Split Payment';
                payment.amount = getSplitPrice(splitPaymentRate, totalToPay, 0);

                params.authPayment = JSON.stringify({
                    paymentType: 1,
                    customerProfileId,
                    customerPaymentProfileId,
                    token: customerPaymentProfileId
                });

               

                let _allEmiRemains = calculateEMIPaymentDates(programs[0].startDate, (splitPaymentRate.noOfTimesPayment - 1), getSplitPrice2(splitPaymentRate, totalToPay));
            

                    params.remainingAmount = totalToPay - getSplitPrice2(splitPaymentRate, totalToPay);

                    if(params.remainingAmount > 0){
                        params.remainingAmount = params.remainingAmount.toFixed(2)
                    }


                    params.isEmi = JSON.stringify({
                        status : 1,
                        month : splitPaymentRate.noOfTimesPayment,
                        remaingMonth: splitPaymentRate.noOfTimesPayment - 1,
                        allEmiRemains: _allEmiRemains
                    })
            }

            params.isWeb = 1;
            params.userId = userId;
            params.cartIds = [];
            params.programs = programs;
            params.billingDetail = JSON.stringify(billingDetail);
            params.payment = JSON.stringify(payment);

            if(insuranceTotal){
                params.insurance = insuranceTotal

                let insuranceQuotes = Object.values(acceptedInsuranceQuotes);
                if (insuranceQuotes.length > 0) {
                  params.insuranceQuotes = insuranceQuotes.map(q => ({quote_id: q.quote.quote_id, premium_amount: q.quote.premium_amount}))
                } 
            }
            

            if (couponMoney) {
                params.couponCode = couponCode;
                params.couponValue = couponMoney.toFixed(2);
                params.discountArr = discountArr;
            }
    
            createBookingByAdminService(params).then(res => {
                let { status, data } = resHandle(res);
                setBtnLoader(false);
                if(status){
                    notification.open({
                        message: 'Booking',
                        description:
                            'Booking create successfully',
                    });
                    history.push('/bookings');
                    
                }
            })
        }  
    }




    const handleAddCardSubmint = () => {
        let validate = true;
        
        setResErr('');

        if(cardNo == ''){
            setCardNoErr('card number error');
            validate = false;
        }else if(cardNo.includes('_')){
            setCardNoErr('card number lenth error');
            validate = false;    
        }

        if(expDate == ''){
            setExpDateErr('card expired date error');
            validate = false;
        }

        if(zipCode == ''){
            setZipCodeErr('zip code error');
            validate = false;
        }

        if(cvc == ''){
            setCvcErr('cvc error');
            validate = false;
        }else if(cvc.includes('_')){
            setCvcErr('cvc lenth error');
            validate = false;    
        }

        if(firstName == ''){
            setFirstNameErr('first name error');
            validate = false;
        }else if(firstName.length > 50){
            setFirstNameErr('First name is to large max limit 50');
            validate = false;
        }

        if(validate){

            setAddCardBtnLoader(true);


            let _cardNo = cardNo.replace(/\s/g,'');
            const encrypted_cardNo = AES.encrypt(_cardNo, settings.api.mySecretKey).toString();
            const encrypted_cvc = AES.encrypt(cvc, settings.api.mySecretKey).toString();

            let params = {
                cardNo: encrypted_cardNo,
                expDate,
                month,
                year,
                cvc: encrypted_cvc,
                firstName,
                lastName,
                email: userEmail,
                userId: userId,
                isWeb: 1,
                campCode: allCampCodes,
                zipCode
            }

           
            createCustomerCardService(params).then(res => {
                setAddCardBtnLoader(false);
                if(res?.data?.statusCode){
                    getCards();
                    setCardNo('');
                    setExpDate('');
                    setCvc('');
                    setFirstName('');
                    setLastName('');
                    setIsNewCard(false);
                }else{
                    setResErr(res.data.error.responseMessage)
                }
                
            })
        }
    }





    const updateInsuranceQuotes = (e, eventName) => {
        const quoteId = e.detail.quote.quote_id;
        const quote = e.detail.quote;

        if (quoteId in acceptedInsuranceQuotes) {
            let updatedAcceptedQuotes = {...acceptedInsuranceQuotes};
            updatedAcceptedQuotes[quoteId] = {quote, eventName};
            setAcceptedInsuranceQuotes(updatedAcceptedQuotes);
        }
    }

   const updateAcceptedInsuranceQuotes = (e, eventName) => {



        const quoteId = e.target.quote?.quote_id;
        const quote = e.target.quote;

        if (e.detail.selectedState === 'accepted' && !(quoteId in acceptedInsuranceQuotes)) {
            let updatedAcceptedQuotes = {...acceptedInsuranceQuotes};
            updatedAcceptedQuotes[quoteId] = {quote, eventName};
            setAcceptedInsuranceQuotes(updatedAcceptedQuotes);
            setInsuranceTotal(insuranceTotal + (quote.premium_amount / 100.00));

        } else if (e.detail.selectedState === 'declined' && (quoteId in acceptedInsuranceQuotes)) {
            let updatedAcceptedQuotes = {...acceptedInsuranceQuotes};
            delete updatedAcceptedQuotes[quoteId];
            setAcceptedInsuranceQuotes(updatedAcceptedQuotes);
            setInsuranceTotal(insuranceTotal - (quote.premium_amount / 100.00));
        }
    }







    return(
        <div>
            {allPrograms.map(item => (
                <div key={item._id} className="c_bor_b">
                    <div className='d-flex justify-content-between date_heading'>
                        <span></span>
                        <span>{item.campName} - {item.programName}</span>
                        <span>{moment(item.startDate).format('MM/DD/YYYY')} to {moment(item.endDate).format('MM/DD/YYYY')}</span>
                    </div>

                    <div className='d-flex justify-content-end pr-4'>
                        <p>Total Cost:</p>
                        <b>${item.f_totalPrice?.toFixed(2)}</b>
                    </div>

                    


                    <div className='d-flex justify-content-end pr-4'>
                        <p>Program Cost:</p>
                        {
                            item.byDates == 2 ? <b>${(item.f_campRegistrationFee - item.f_registerNonRefundFee)?.toFixed(2)}</b> : <b>${(item.f_campRegistrationFee)?.toFixed(2)}</b>
                        }
                    </div>

                    {
                        item.byDates == 2 && item.f_registerNonRefundFee ?
                        <div className="d-flex justify-content-end pr-4">
                            <p>Non Refundable Registration Amount:</p>
                            <b>${item.f_registerNonRefundFee?.toFixed(2)}</b>
                        </div> : ''
                    }

                    
                    {
                        item.f_additionalTotal ? (
                            <div className='d-flex justify-content-end pr-4'>
                                <p>Additional Experience Total:</p>
                                <b>${item.f_additionalTotal?.toFixed(2)}</b>
                            </div> 
                        ): ''
                    }

                    
                    <div className='d-flex justify-content-end pr-4'>
                        <p>Convenience Fee:</p>
                        <b>${item.f_convenienceFee?.toFixed(2)}</b>
                    </div>

                    <div className='d-flex justify-content-end pr-4'>
                        <p>Taxes:</p>
                        <b>${item.f_taxPrice?.toFixed(2)}</b>
                    </div>


                    {
                        item._additionalDiscount ?
                            <div className='d-flex justify-content-end pr-4'>
                                <p>Additional Discount:</p>
                                <b>-${item._additionalDiscount?.toFixed(2)}</b>
                            </div>: ''
                    }


                    {
                        item.f_siblingDiscount ? (
                            <div className="d-flex justify-content-end pr-4">
                                    <p>Sibling Discount:</p><b>${item.f_siblingDiscount?.toFixed(2)}</b>
                            </div>
                        ) : ''
                    }

                    {
                        item.f_multiProgramDiscount ? (
                            <div className="d-flex justify-content-end pr-4">
                                <p>Multi Programs Discount:</p><b>${item.f_multiProgramDiscount?.toFixed(2)}</b>
                            </div>
                        ) : ''
                    }


                    {
                        item.campers.map(_camper => (
                            <div className=''>
                                {
                                    _camper.selectedProduct.length ? _camper.selectedProduct.map(_product => (
                                        <div key={_product._id} className="d-flex justify-content-end pr-4">
                                            <p>{_product.title} {_product.count}:</p><b>${(_product.count * _product.price).toFixed(2)}</b>
                                        </div>
                                    )) : ''
                                }
                            </div>
                        ))
                    }


            {
                subtotal ? <InsuranceComponentMemo
                style={{fontSize: "0.95rem"}}
                insuranceTotal={insuranceTotal}
                acceptedInsuranceQuotes={acceptedInsuranceQuotes}
                id={`insurance-offer-${item._id}`}
                data-unique-id={item._id}
                // customer={{
                //     first_name: user_data.name,
                //     last_name: user_data.name,
                //     email_address: userEmail,
                //     address: 'NA',
                //     city: 'NA',
                //     postal_code: item.zipCode || '89109',
                //     state: item.insuranceState || 'MN'
                // }}

                customer={{
                    'state': item.state,
                    'postal_code': item.zipCode,
                    'first_name': user_data.name,
                    'last_name': user_data.name,
                    'email_address': userEmail,
                    'address': 'NA',
                    'city': 'NA',
                }}
                client-id="live_OS3ZRXTLX720CFG3PYVR9NW7AC4RODA9"
                event-start-date={new Date(item.startDate).toISOString().substring(0, 10)}
                event-end-date={new Date(item.endDate).toISOString().substring(0, 10)}
                //insurable-amount={item.byDates == 1 ? (getDatesPrices(item.additionalDates, item.selectedDates, item.campers) * 100).toFixed(0) : (((item.registrationFee * item.campers.length) - ((item.campers.length * item.registerNonRefundFee) + (item.convenienceFee * item.campers.length) + ((item.taxPrice || 0) * item.campers.length))) * 100).toFixed(0)}
                insurable-amount={(item.f_totalPrice * 100).toFixed(0)}
                participant-type="PARTICIPANT"
                theme={JSON.parse(insuranceComponentTheme)}
                onQuoteChange={(e) => updateInsuranceQuotes(e, `${item.campName} - ${item.programName}`)}
                onQuoteStateChange={(e) => updateAcceptedInsuranceQuotes(e, `${item.campName} - ${item.programName}`)}
            /> : ''
            }


                
                </div>

            ))}






                <div className="c_bor_b">
                    <h4>Coupons & Rewards</h4>
                    <p><small>If you have a Coupon, or Rewards Code enter it here to redeem.</small></p>
                    <div className="d-flex justify-content-end align-items-center pr-4">
                        <label className="m-0">Enter Code:</label>
                        <div className="position-relative">
                            <input
                                type="text"
                                value={couponCode}
                                onChange={e => (setCouponCode(e.target.value), setCouponCodeErr(''))}
                                placeholder="Enter Coupon Code"
                                className="form-control w-auto ml-2 mr-2" />
                            {couponCodeErr ? <span className="cm_err_msg2">{couponCodeErr}</span> : ''}
                        </div>
                        <span onClick={couponCodeLoader ? null : handleCouponCodeSubmit} className={`btn btn-primary pl-5 pr-5 ${couponCodeLoader ? 'btnLoader' : ''}`}>Apply</span>
                    </div>
                    {couponCodeSuccess ? <p className="cm_success_msg">{couponCodeSuccess}</p> : ''}
                </div>



                <div className="c_bor_b">
                    <h4>Payment Method </h4>
                    <div className="d-flex justify-content-end pr-4">
                        <p>Subtotal:</p><b> ${subtotal.toFixed(2)}</b>
                    </div>


                    <Fragment>
                            {Object.values(acceptedInsuranceQuotes).map((q, i) => (
                            <div key={i} className="d-flex justify-content-end pr-4">
                                <p>Registration Insurance ({q.eventName}):</p><b> ${(q.quote.premium_amount / 100).toFixed(2)}</b>
                            </div>
                            ))}
                    </Fragment>


                    {
                        couponMoney ? (
                            <div className="d-flex justify-content-end pr-4">
                                <p>Coupon Applied:</p><b>${couponMoney.toFixed(2)}</b>
                            </div>
                        ) : ''
                    }
                </div>

                <div className="c_bor_b">
                    <div className={`d-flex justify-content-end pr-4`}>
                        <p>Total to Pay:</p>
                        <b>${totalToPay.toFixed(2)}</b>
                    </div>

                    <h4 className="mb-4">Billing Information</h4>
                </div>




    {
                totalToPay > 0 ? (
                    <Fragment>
                        <h5 className='mb-3'>Select a Payment Option</h5>

                        <div className="row">

                            {
                                creditCardRate.status == 1 ? (
                                    <div className="col-md-4">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 1 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(1), setPaymentMethodErr(''))}>

                                            <h4>Credit Card / Debit Card</h4>
                                            <h2>${getWithTaxPrice(creditCardRate, totalToPay, insuranceTotal)}</h2>
                                            <p>Payment Includes {creditCardRate.percentage}% + {`$${creditCardRate.taxRate}`} Processing Charge</p>
                                        </div>
                                    </div>
                                ) : ''
                            }



                            {
                                achTransferRate.status == 1 ? (
                                    <div className="col-md-4">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 3 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(3), setPaymentMethodErr(''))}>

                                            <h4>Internet Banking</h4>
                                            <h2>${getWithTaxPrice(achTransferRate, totalToPay, insuranceTotal)}</h2>
                                            <p>Payment Includes {achTransferRate.percentage}% + {`$${achTransferRate.taxRate}`} Processing Charge</p>
                                        </div>
                                    </div>
                                ) : ''
                            }

                            

                            {
                                byCheque.status == 1 ? (
                                    <div className="col-md-4">
                                        <div
                                            className={`payment-gateway-card ${paymentMethod == 4 ? 'active' : ''}`}
                                            onClick={() => (setPaymentMethod(4), setPaymentMethodErr(''))}>
                                            <h4>Check</h4>
                                            <h2>${getWithTaxPrice(byCheque, totalToPay, insuranceTotal)}</h2>
                                            <p>Payment Includes {`$${byCheque.taxRate}`} Processing Charge</p>

                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Payment will be made via mail or in person. Insurance coverages are not available if paying by check</Tooltip>}>
                                                    <span className='my_tooltip'><InformationIcon /></span>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                ) : ''
                            }





                            {
                                splitPaymentRate.status == 1 && splitPaymentRate.noOfTimesPayment > 1 ? (
                                    <div className="col-md-4">
                                    <div
                                        className={`payment-gateway-card ${paymentMethod == 5 ? 'active' : ''}`}
                                        onClick={() => (setPaymentMethod(5), setPaymentMethodErr(''))}>

                                            <h4>Split Payment Credit Card</h4>
                                            <h2>${getSplitPrice(splitPaymentRate, totalToPay, insuranceTotal)}</h2>
                                            <p><b>{splitPaymentRate.noOfTimesPayment}</b> Payments</p>
                                            <p>Payment Includes {splitPaymentRate.percentage}% + {`$${splitPaymentRate.taxRate}`} Processing Charge</p>
                                            
                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip>Card automatically charged 30 days later for subseqent payements</Tooltip>}>
                                                    <span className='my_tooltip'><InformationIcon /></span>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                ) : ''
                            }

        
                        </div>


                        {paymentMethodErr ? <p className="color_red">{paymentMethodErr}</p> : ''}

                        {
                            (paymentMethod == 1 || paymentMethod == 5) ? (
                                <Fragment>
                                    <ul className="card_list">
                                        {cardList.length ? cardList.map(item => (
                                                <li key={item.signature} className={customerPaymentProfileId === item.card.token ? 'active' : ''}>
                                                <div className="check_box_wrapper cm_dark_check pt-1">
                                                    <input
                                                        checked={customerPaymentProfileId === item.card.token}
                                                        onChange={() => (setCustomerPaymentProfileId(item.card.token), setCustomerPaymentProfileIdError(''))}
                                                        type="radio"
                                                        name="gender"
                                                        value="male" />
                                                    <span className="check_box"><span></span></span>
                                                </div>
                                                <div className="p_card_img ml-4">
                                                    <img src={item?.card?.brand ? require(`../../assets/img/${getCardTypeName(item.card.brand)}.svg`) : require(`../../assets/img/Visa.svg`)} />
                                                </div>
                                                <p>********{item.card.acct_last_four}</p>
                                            </li>

                                            )) : ''
                                        }
                                    </ul>

                                    {customerPaymentProfileIdError ? <p className="color_red text-center">{customerPaymentProfileIdError}</p> : ''}


                                {
                                    isNewCard ? (
                                        <div className="card_inputs add_card_form">
                                            <div className="form-group">
                                                <label>Card Number</label>
                                                <InputMask
                                                    className={`form-control ${cardNoErr ? 'is-invalid' : ''}`}
                                                    name="cardNumber"
                                                    placeholder="XXXX XXXX XXXX XXXX"
                                                    mask="9999 9999 9999 9999"
                                                    onKeyPress={() => setCardNoErr('')}
                                                    onChange={e => setCardNo(e.target.value)} />

                                                    {cardNoErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cardNoErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            <div className="row">
                                                <div className="col form-group">
                                                    <label>Expiry Date</label>
                                                    <DatePicker
                                                        className={`form-control ${expDateErr ? 'is-invalid' : ''}`}
                                                        format="MM/YYYY"
                                                        picker="month"
                                                        placeholder="MM/YYYY"
                                                        onKeyPress={() => setExpDateErr('')}
                                                        onChange={e => (setMonth(`${addZero(e._d.getMonth() + 1)}`), setYear(`${e._d.getFullYear().toString()}`), setExpDate(`${addZero(e._d.getMonth() + 1)}${e._d.getFullYear().toString()}`), setExpDateErr(''))} />

                                                    {expDateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{expDateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>
                                                <div className="col form-group">
                                                    <label>CVC</label>
                                                    <InputMask
                                                        className={`form-control ${cvcErr ? 'is-invalid' : ''}`}
                                                        placeholder="XXX"
                                                        mask="999"
                                                        onKeyPress={() => setCvcErr('')}
                                                        onChange={e => setCvc(e.target.value)} />

                                                        {cvcErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cvcErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label>Cardholder First Name</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${firstNameErr ? 'is-invalid' : ''}`}
                                                    placeholder="Cardholder First Name"
                                                    onKeyPress={() => setFirstNameErr('')}
                                                    onChange={e => setFirstName(e.target.value)} />

                                                    {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            <div className="form-group">
                                                <label>Cardholder Last Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Cardholder Last Name"
                                                    onChange={e => setLastName(e.target.value)} />
                                            </div>

                                            <div className="form-group">
                                                <label>Zip Code</label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${zipCodeErr ? 'is-invalid' : ''}`}
                                                    placeholder="Zip Code"
                                                    onChange={e => (setZipCode(e.target.value), setZipCodeErr(''))} />

                                                    {zipCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{zipCodeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                            </div>

                                            {resErr ? <h6 className="res_error">{resErr}</h6> : ''}

                                            <div className="d-flex justify-content-between">
                                            <span className="btn btn-info rounded-0 w-100 mr-2">Cancel</span>
                                            <span onClick={addCardBtnLoader ? null : handleAddCardSubmint} className={`btn btn-primary rounded-0 w-100  ${addCardBtnLoader ? 'btnLoader' : ''}`}>Add</span>
                                            </div>


                                            </div>
                                    ) : <button className="btn btn-primary mb-3" onClick={() => setIsNewCard(true)}>Add New Card</button>
                                } 
                            </Fragment>
                            ) : ''
                        }





{    
                            paymentMethod == 3 ? (
                                <Fragment>
                                    <div className="card_inputs add_card_form">

                                        <div className="form-group">
                                            <label>Select Account Type</label>
                                            <Select
                                                className={`acc_select ${bankAccountTypeErr ? 'is-invalid' : ''}`}
                                                placeholder="Select Account Type"
                                                onChange={e => (setBankAccountType(e), setBankAccountTypeErr(''))}>

                                                <Option value="checking">Checking</Option>
                                                <Option value="savings">Savings</Option>
                                                <Option value="businessChecking">business Checking</Option>

                                            </Select>

                                            {bankAccountTypeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountTypeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>


                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <input
                                                type='number'
                                                name="bankAccountNum"
                                                className={`form-control ${bankAccountNumErr ? 'is-invalid' : ''}`}
                                                value={bankAccountNum}
                                                placeholder="Account Number"
                                                onChange={e => (setBankAccountNum(e.target.value), setBankAccountNumErr(''))} />

                                            {bankAccountNumErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{bankAccountNumErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>



                                        <div className="form-group">
                                            <label>Routing Number</label>
                                            <input
                                                type='number'
                                                className={`form-control ${routingNumberErr ? 'is-invalid' : ''}`}
                                                value={routingNumber}
                                                placeholder="Routing Number"
                                                name="routingNumber"
                                                onChange={e => (setRoutingNumber(e.target.value), setRoutingNumberErr(''))} />

                                            {routingNumberErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{routingNumberErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                                        </div>


                                        <div className="form-group">
                                            <label>Account Holder Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${nameOnAccountErr ? 'is-invalid' : ''}`}
                                                name="nameOnAccount"
                                                value={nameOnAccount}
                                                onChange={e => (setNameOnAccount(e.target.value), setNameOnAccountErr(''))}
                                                placeholder="Account Holder Name" />

                                            {nameOnAccountErr? <OverlayTrigger placement="left" overlay={<Tooltip>{nameOnAccountErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                                        </div>

                                        </div>   
                            </Fragment>
                            ) : ''
                        }

                    </Fragment>
                ) : ''
            }


            <div className='d-flex justify-content-between mt-5'>
                <span onClick={handleBack} className='btn btn-dark'>Back</span>
                <div>{resErr ? <span className='cm_error text-left'>{resErr}</span> : ''}</div>
                <span className='btn btn-primary' onClick={() => !btnLoader ? handleCreateBooking() : null}>Submit Registration</span>
            </div>
        </div>
    )
}

export default PaymentInformationCompoant;