import React, { useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

import PhoneInput from 'react-phone-number-input/input';
import { DatePicker, notification } from 'antd';
import Select from 'react-select';
import moment from 'moment';



import { allergyList, genders2, medicalList, shortsAndTshirtSizes, removeTime, gradeArr2, resHandle } from '../../helper';
import { uploadMediaService, editParticipantService } from '../../services/userService';
import { PDFIcon } from '../reuse/SVG';


const dateFormatNew = 'MM/DD/YYYY';

const convertVal = (value) => {
    if(!value){
        return ['None']
    }else{
        if(value?.includes('None')){
            return ['None']
        }else{
            let _val = JSON.parse(value);
            if(_val?.length){
                let _value = []
                let isObj = _val.map(i => i.value);
        
                if(isObj[0] == undefined){
                    _value = _val
                }else{
                    _value = isObj
                }
        
                // if(_value?.includes('None')){
                //     return ['None']
                // }else{
                //     return _value
                // }
            }
        }
    }

   

    
}



const EditCamperModal = props => {
    const [btnloader, setBtnloader] = useState(false);

    const[camperId, setCamperId] = useState('')
    const[address, setAddress] = useState('')
    const[allergy, setAllergy] = useState([])
    const[allergyDeadly, setAllergyDeadly] = useState('')
    const[allowDocumentCamper, setAllowDocumentCamper] = useState('')
    const[apartment, setApartment] = useState('')
    const[city, setCity] = useState('')
    const[currentGrade, setCurrentGrade] = useState('')
    const[dob, setDob] = useState('')
    const[firstName, setFirstName] = useState('')
    const[gender, setGender] = useState('')
    const[group, setGroup] = useState('')
    const[groupcode, setGroupcode] = useState('')
    const[lastName, setLastName] = useState('')
    const[medical, setMedical] = useState([])
    const[medicalDocument, setMedicalDocument] = useState('')
    const[medicalInsuranceCompany, setMedicalInsuranceCompany] = useState('')
    const[medicationsTaken, setMedicationsTaken] = useState('')
    const[physicianPhone, setPhysicianPhone] = useState('')
    const[participantBringingOwnMedication, setParticipantBringingOwnMedication] = useState('')
    const[participantReceiveCounterMedication, setParticipantReceiveCounterMedication] = useState('')
    const[permissionEmergencyMedication, setPermissionEmergencyMedication] = useState('')
    const[physicianAddress, setPhysicianAddress] = useState('')
    const[policyNumber, setPolicyNumber] = useState('')
    const[primaryPhysician, setPrimaryPhysician] = useState('')
    const[profileImage, setProfileImage] = useState('')
    const[shortsSize, setShortsSize] = useState('')
    const[state, setState] = useState('')
    const[tshirtSize, setTshirtSize] = useState('')
    const[zipCode, setZipCode] = useState('')


    const[addressErr, setAddressErr] = useState('')
    const[allergyErr, setAllergyErr] = useState('')
    const[cityErr, setCityErr] = useState('')
    const[currentGradeErr, setCurrentGradeErr] = useState('')
    const[dobErr, setDobErr] = useState('')
    const[firstNameErr, setFirstNameErr] = useState('')
    const[genderErr, setGenderErr] = useState('')
    const[lastNameErr, setLastNameErr] = useState('')
    const[medicalErr, setMedicalErr] = useState('')
    const[shortsSizeErr, setShortsSizeErr] = useState('')
    const[stateErr, setStateErr] = useState('')
    const[tshirtSizeErr, setTshirtSizeErr] = useState('')
    const[zipCodeErr, setZipCodeErr] = useState('')
    const[allergyDeadlyErr, setAllergyDeadlyErr] = useState('')


    useEffect(() => {
        if(props?.data?._id){
            setCamperId(props?.data?._id);
            setAddress(props?.data?.address || '')
            //setAllergy(props?.data?.allergy || [])
            setAllergyDeadly(props?.data?.allergyDeadly || '')
            setAllowDocumentCamper(props?.data?.allowDocumentCamper || '')
            setApartment(props?.data?.apartment || '')
            setCity(props?.data?.city || '')
            setCurrentGrade(props?.data?.currentGrade || '')
            setDob(props?.data?.dob || '')
            setFirstName(props?.data?.firstName || '')
            setGender(props?.data?.gender || '')
            setGroup(props?.data?.group || '')
            setGroupcode(props?.data?.groupcode || '')
            setLastName(props?.data?.lastName || '')
            setMedicalDocument(props?.data?.medicalDocument || '')
            setMedicalInsuranceCompany(props?.data?.medicalInsuranceCompany || '')
            setMedicationsTaken(props?.data?.medicationsTaken || '')
            setParticipantBringingOwnMedication(props?.data?.participantBringingOwnMedication || '')
            setParticipantReceiveCounterMedication(props?.data?.participantReceiveCounterMedication || '')
            setPermissionEmergencyMedication(props?.data?.permissionEmergencyMedication || '')
            setPhysicianAddress(props?.data?.physicianAddress || '')
            setPolicyNumber(props?.data?.policyNumber || '')
            setPrimaryPhysician(props?.data?.primaryPhysician || '')
            setProfileImage(props?.data?.profileImage || '')
            setShortsSize(props?.data?.shortsSize || '')
            setState(props?.data?.state || '')
            setTshirtSize(props?.data?.tshirtSize || '')
            setZipCode(props?.data?.zipCode || '')
            setMedical(convertVal(props?.data?.medical));
            setAllergy(convertVal(props?.data?.allergy));


            

            // if(props?.data?.medical != undefined) {
            //     let _medical = JSON.parse(props?.data?.medical);
            //     if(Array.isArray(_medical)){
            //         setMedical(_medical);
            //     }
            //     else{
            //         setMedical(['None'])
            //     } 
            // }

            // if(props?.data?.allergy != undefined) {
            //     let _allergy = JSON.parse(props?.data?.allergy);
            //     if(Array.isArray(_allergy)){
            //         setAllergy(_allergy);
            //     }
            //     else{
            //         setAllergy(['None'])
            //     } 
            // }


        }
    }, props.data)



    const handleDateChange = (e) => {
        setDobErr('')
        if(e != undefined && e != null){
            let temp = new Date(removeTime(e._d)).getTime()
            setDob(temp);
        } 
    }


    const handleMultiSelectChangeMedical = (e) => {
       let _medical = [];
        if(e != undefined && e != null){
            let isNone = e.find(m => m.value == 'None');
            if(isNone){
                _medical = [isNone.value];
            }else{
                _medical = e.map(m => m.value);
            }
        }else{
            _medical = []; 
        }
        setMedical(_medical)
    }


    const handleMultiSelectChangeAllergy = (e) => {
        let _allergy = [];
         if(e != undefined && e != null){
             let isNone = e.find(m => m.value == 'None');
             if(isNone){
                _allergy = [isNone.value];
             }else{
                _allergy = e.map(m => m.value);
             }
         }else{
            _allergy = []; 
         }
         setAllergy(_allergy)
    }


    const handlePhoneChange = (e) => {
        if(e != undefined && e != null){
            setPhysicianPhone(e)
        } 
    }


    const uploadHippaCompliance = (e) => {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        uploadMediaService(data).then(res => {
            if(res.data.responseData.result.fileUrl != undefined){
                setMedicalDocument(res.data.responseData.result.fileUrl);
            }
        })
    } 

    const handleFileChange = (e) => {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        uploadMediaService(data).then(res => {
            if(res.data.responseData.result.fileUrl != undefined){
                setProfileImage(res.data.responseData.result.fileUrl);
            }
        })
    } 


    const  disabledDate = (current) => {
        return current && (current > moment().startOf('day') || current < moment(`${new Date().getFullYear() - 100}-10-23`).startOf('day'));
    }



    const handleValidate = () => {
        let validate = true;
        
        if(address == ''){
            setAddressErr('Address is required');
            validate = false
        }

        if(allergy == ''){
            setAllergyErr('Allergy is required');
            validate = false
        }

        // if(apartment == ''){
        //     setApartmentErr('Apartment is required');
        //     validate = false
        // }

        if(city == ''){
            setCityErr('City is required');
            validate = false
        }

        if(currentGrade == ''){
            setCurrentGradeErr('Grade is required');
            validate = false
        }

        if(dob == ''){
            setDobErr('Date of birth is required');
            validate = false
        }

        if(firstName == ''){
            setFirstNameErr('First name is required');
            validate = false
        }

        if(gender == ''){
            setGenderErr('Gender is required');
            validate = false
        }

        if(lastName == ''){
            setLastNameErr('Last name is required');
            validate = false
        }

        if(medical == ''){
            setMedicalErr('Medical is required');
            validate = false
        }

        if(shortsSize == ''){
            setShortsSizeErr('Shorts size is required');
            validate = false
        }

        if(state == ''){
            setStateErr('State is required');
            validate = false
        }

        if(tshirtSize == ''){
            setTshirtSizeErr('Tshirt size is required');
            validate = false
        }

        if(zipCode == ''){
            setZipCodeErr('Zip code is required');
            validate = false
        }

        if(allergyDeadly == ''){
            setAllergyDeadlyErr('Allergy deadly is required');
            validate = false
        }

        return validate;
    }


    const handleSubmit = e => {

        if(handleValidate()){
            setBtnloader(true);
           
            let params = {
                _id: camperId,
                address,
                allergyDeadly,
                allowDocumentCamper,
                apartment,
                city,
                currentGrade,
                dob,
                firstName,
                gender,
                group,
                groupcode,
                lastName,
                allergy: JSON.stringify(allergy),
                medical: JSON.stringify(medical),
                medicalDocument,
                medicalInsuranceCompany,
                medicationsTaken,
                physicianPhone,
                participantBringingOwnMedication,
                participantReceiveCounterMedication,
                permissionEmergencyMedication,
                physicianAddress,
                policyNumber,
                primaryPhysician,
                profileImage,
                shortsSize,
                state,
                tshirtSize,
                zipCode,
                activityInterest: "[]"
            }

            let obj = {
                deviceTypeID: 3,
                participants: JSON.stringify([params])
            }

   
            

            editParticipantService(obj).then(res => {
                setBtnloader(false);
                let { status, data } = resHandle(res);
                if(status){
                    props.closeEditModal();
                    notification.open({
                        message: 'Participants',
                        description:
                          'Participant update successfully',
                      });
                   }
                

            
            })

            

        }

    }




  

    return (
        <Modal
            size="lg"
            show={props.isOpen}
            animation={false}
            centered >
            <Modal.Header>
                <Modal.Title>Edit Participant</Modal.Title>
                <span className="modal_close" onClick={props.closeEditModal}><i className="icon-cross" /></span>
            </Modal.Header>

            <Modal.Body>
            <div className='spaceform'>

            <div className="profile_upload">
                {profileImage ? <img src={profileImage} /> : ''}
                <input onChange={handleFileChange} value="" type="file" accept="image/x-png,image/gif,image/jpeg"/>
                <i className="icon-camera-plus" />
            </div>



                <div className='row'>

                    <div className="col-lg-5 d-flex align-items-center mb-4">
                        <label style={{minWidth: '110px'}}>Participant :</label>
                        <input
                            disabled
                            value={groupcode}
                            type="text"
                            className='form-control'
                            placeholder="Group Code" />   
                    </div> 

                    <div className='col-md-12'></div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>First Name</label>
                                <input
                                    value={firstName}
                                    onChange={e => (setFirstName(e.target.value), setFirstNameErr(''))}
                                    type='text'
                                    className={`form-control ${ firstNameErr ? 'is-invalid' : '' }`} />

                                {firstNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{firstNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>

                        


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Last Name</label>
                                <input
                                    value={lastName}
                                    onChange={e => (setLastName(e.target.value), setLastNameErr(''))}
                                    type='text'
                                    className={`form-control ${ lastNameErr ? 'is-invalid' : '' }`} />

                                {lastNameErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{lastNameErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Date of Birth</label>
                                <DatePicker
                                    disabledDate={disabledDate}
                                    value={dob ? moment.utc(parseInt(dob)): ''}
                                    className={`form-control ${ dobErr ? 'is-invalid' : '' }`}
                                    placeholder="Date of Birth"
                                    format={dateFormatNew}
                                    onChange={handleDateChange} />

                                {dobErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{dobErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Select Current Grade As of Today</label>
                                <Select
                                    placeholder="Select shorts size"
                                    onChange={e => (setCurrentGrade(e.value), setCurrentGradeErr(''))}
                                    classNamePrefix="cm_select"
                                    value={currentGrade ? {label: currentGrade, value: currentGrade} : ''}
                                    options={gradeArr2.map(i => ({value: i, label: i}))}
                                    className={`form-control ${currentGradeErr ? 'is-invalid' : ''}`}
                                />

                                {currentGradeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{currentGradeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>


                        <div className="col-md-12 d-flex cm_gendr pro_wrapper">
                            <label>Gender</label>
                            <ul className="d-flex">
                                {
                                    genders2.map((itm, ind) => (
                                        <li className="mr-2 check_box_wrapper" key={ind}>
                                            <input
                                                type="checkbox"
                                                name="gender"
                                                checked={gender == itm.value ? 'checked': ''}
                                                value={gender}
                                                onChange={e => (setGender(itm.value), setGenderErr(''))}
                                            />
                                            <span className="check_box"><span /> {itm.label}</span> 
                                        </li>
                                    ))
                                }
                            </ul>

                            <div>{genderErr ? <span className='cm_error'>{genderErr}</span> : ''}</div>
                        </div> 


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Street Address</label>
                                <input
                                    value={address}
                                    onChange={e => (setAddress(e.target.value), setAddressErr(''))}
                                    type='text'
                                    className={`form-control ${ addressErr ? 'is-invalid' : '' }`} />

                                    {addressErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{addressErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Apt./Suite/Unit [optional]</label>
                                <input
                                    value={apartment}
                                    onChange={e => setApartment(e.target.value)}
                                    type='text'
                                    className={`form-control`} />

                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>City</label>
                                <input
                                    value={city}
                                    onChange={e => (setCity(e.target.value), setCityErr(''))}
                                    type='text'
                                    className={`form-control ${ cityErr ? 'is-invalid' : '' }`} />

                                    {cityErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{cityErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Zip Code</label>
                                <input
                                    value={zipCode}
                                    onChange={e => (setZipCode(e.target.value), setZipCodeErr(''))}
                                    type='text'
                                    className={`form-control ${ zipCodeErr ? 'is-invalid' : '' }`} />

                                    {zipCodeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{zipCodeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>


                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label>State/Province</label>
                                <input
                                    value={state}
                                    onChange={e => (setState(e.target.value), setStateErr(''))}
                                    type='text'
                                    className={`form-control ${ stateErr ? 'is-invalid' : '' }`} />

                                    {stateErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{stateErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>T-shirt Size</label>
                                <Select
                                    placeholder="Select t-shirt size"
                                    onChange={e => (setTshirtSize(e.value), setTshirtSizeErr(''))}
                                    classNamePrefix="cm_select"
                                    className={`form-control ${tshirtSizeErr ? 'is-invalid' : ''}`}
                                    value={tshirtSize ? {label: tshirtSize, value: tshirtSize} : ''}
                                    options={shortsAndTshirtSizes.map(i => ({value: i, label: i}))} />

                                {tshirtSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{tshirtSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Shorts Size</label>
                                <Select
                                    placeholder="Select shorts size"
                                    onChange={e => (setShortsSize(e.value), setShortsSizeErr(''))}
                                    classNamePrefix="cm_select"
                                    className={`form-control ${shortsSizeErr ? 'is-invalid' : ''}`}
                                    value={shortsSize ? {label: shortsSize, value: shortsSize} : ''}
                                    options={shortsAndTshirtSizes.map(i => ({value: i, label: i}))} />

                                {shortsSizeErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{shortsSizeErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Medical</label>
                                <Select
                                    isMulti
                                    placeholder="Select medical"
                                    onChange={handleMultiSelectChangeMedical}
                                    classNamePrefix="cm_select"
                                    value={medical.length ? medical.map(i => ({value: i, label: i})) : []}
                                    options={medicalList.map(i => ({value: i, label: i}))}
                                    className={`form-control ${medicalErr ? 'is-invalid' : ''}`}
                                />

                                {medicalErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{medicalErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}

                            </div>
                        </div>



                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Allergy</label>
                                <Select
                                    isMulti
                                    placeholder="Select allergy"
                                    onChange={handleMultiSelectChangeAllergy}
                                    classNamePrefix="cm_select"
                                    value={allergy ? allergy.map(i => ({value: i, label: i})) : []}
                                    options={allergyList.map(i => ({value: i, label: i}))}
                                    className={`form-control ${allergyErr ? 'is-invalid' : ''}`}
                                />

                                {allergyErr ? <OverlayTrigger placement="left" overlay={<Tooltip>{allergyErr}</Tooltip>}><i className="input_info" /></OverlayTrigger> : ''}
                            </div>
                        </div>


                        <div className="col-md-12 d-flex cm_gendr pro_wrapper">
                            <label>Allergy is deadly</label>
                            <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        onChange={e => (setAllergyDeadly(1), setAllergyDeadlyErr(''))}
                                        type="radio"
                                        value={1}
                                        checked={allergyDeadly == 1}
                                        name="allergyDeadly" />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                       onChange={e => (setAllergyDeadly(2), setAllergyDeadlyErr(''))}
                                        type="radio"
                                        value={2}
                                        checked={allergyDeadly == 2}
                                        name="allergyDeadly" />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>
                        </div> 


                        <div className='col-md-12'>
                            <h5>Insurance Information:</h5>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Medical Insurance Company</label>
                                <input
                                    value={medicalInsuranceCompany}
                                    onChange={e => setMedicalInsuranceCompany(e.target.value)}
                                    type='text'
                                    className='form-control' />
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Policy Number</label>
                                <input
                                    value={policyNumber}
                                    onChange={e => setPolicyNumber(e.target.value)}
                                    type='text'
                                    className='form-control' />
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Group #</label>
                                <input
                                    value={group}
                                    onChange={e =>setGroup(e.target.value)}
                                    type='text'
                                    className='form-control' />
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Primary Physician</label>
                                <input
                                    value={primaryPhysician}
                                    onChange={e => setPrimaryPhysician(e.target.value)}
                                    type='text'
                                    className='form-control' />
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Physician Address</label>
                                <input
                                    value={physicianAddress}
                                    onChange={e => setPhysicianAddress(e.target.value)}
                                    type='text'
                                    className='form-control' />
                            </div>
                        </div>


                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Physician Phone #</label>
                                <PhoneInput
                                    country="US"
                                    placeholder="Phone number"
                                    className={`form-control`}
                                    onChange={handlePhoneChange}
                                    maxLength={16}
                                    value={physicianPhone}
                                    name="phone" />
                            </div>
                        </div>


                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label>Please list any medications taken(please include dosage & timing) or any other special assistance necessary to attend a program. (Leave blank if none)</label>
                                <textarea
                                    value={medicationsTaken}
                                    onChange={e => setMedicationsTaken(e.target.value)}
                                    className='form-control' />
                            </div>
                        </div>


                        <div className="col-md-12 form-group">
                            <label>Parent Permission for EMERGENCY MEDICATIONS: (rescue inhaler, glucagon, epinephrine)</label>
                        </div>


                        <div className="col-md-12 cm_gendr pro_wrapper">
                            <label>Participant has permission to carry his/her own emergency medication, or inhaler:</label>
                            <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        onChange={e => setPermissionEmergencyMedication(1)}
                                        type="radio"
                                        value={1}
                                        checked={permissionEmergencyMedication == 1}
                                        name="permissionEmergencyMedication" />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        onChange={e => setPermissionEmergencyMedication(2)}
                                        type="radio"
                                        value={2}
                                        checked={permissionEmergencyMedication == 2}
                                        name="permissionEmergencyMedication" />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>
                        </div> 



                        <div className="col-md-12 cm_gendr pro_wrapper">
                            <label>May the participant receive over-the counter medication:</label>
                            <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        onChange={e => setParticipantReceiveCounterMedication(1)}
                                        type="radio"
                                        value={1}
                                        checked={participantReceiveCounterMedication == 1}
                                        name="participantReceiveCounterMedication" />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        onChange={e => setParticipantReceiveCounterMedication(2)}
                                        type="radio"
                                        value={2}
                                        checked={participantReceiveCounterMedication == 2}
                                        name="participantReceiveCounterMedication" />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>
                        </div> 



                        <div className="col-md-12 cm_gendr pro_wrapper">
                            <label>Will the participant be bringing their own medication, or an inhaler to a program: </label>
                            <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        onChange={e => setParticipantBringingOwnMedication(1)}
                                        type="radio"
                                        value={1}
                                        checked={participantBringingOwnMedication == 1}
                                        name="participantBringingOwnMedication" />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        onChange={e => setParticipantBringingOwnMedication(2)}
                                        type="radio"
                                        value={2}
                                        checked={participantBringingOwnMedication == 2}
                                        name="participantBringingOwnMedication" />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>
                        </div> 


                        <div className="col-md-12 form-group">
                            <label>If the Participant is bringing medication please note:</label>
                            <label>All Medication must be sent in the original container and must state the current instructions for dispensing.  All medications must be turned in when attendee is dropped off, if permission is not granted above for them to carry it.</label> 
                        </div> 


                        <div className="col-md-12 cm_gendr pro_wrapper">
                            <label>Service Provider Staff may document participant with digital and film photography and/or video.</label>
                            <ul className="d-flex">
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        onChange={e => setAllowDocumentCamper(1)}
                                        type="radio"
                                        value={1}
                                        checked={allowDocumentCamper == 1}
                                        name="allowDocumentCamper" />
                                    <span className="check_box"><span /> Yes</span> 
                                </li>
                                <li className="mr-2 check_box_wrapper">
                                    <input
                                        onChange={e => setAllowDocumentCamper(2)}
                                        type="radio"
                                        value={2}
                                        checked={allowDocumentCamper == 2}
                                        name="allowDocumentCamper" />
                                    <span className="check_box"><span /> No</span> 
                                </li>
                            </ul>
                        </div> 


                        <div className="col-md-12 form-group">
                            <label>Please attach any accompanying documents that you feel are necessary for attendance (Additional Medical document etc).</label>
                            <div className="attach_file_btn">
                                Attach PDF 
                                <input
                                    type="file"
                                    value=""
                                    accept="application/pdf"
                                    onChange={uploadHippaCompliance} />
                            </div>

                            {
                                medicalDocument ? <div className="cm_pdf_icon"><PDFIcon /><i className="icon-cross" onClick={() => setMedicalDocument('')} /> <p>{medicalDocument ? medicalDocument.slice(medicalDocument.lastIndexOf('_') + 1) : ''}</p></div> : ''  
                            }
                                                            
                        </div>

                    


                    </div>
                </div>

                <div className='text-center'>
                        <button
                            disabled={btnloader}
                            onClick={handleSubmit}
                            className={`btn btn-primary rounded-0 ${btnloader ? 'btnLoader' : ''}`}>Save</button>
                    </div>
            </Modal.Body>
        </Modal>

    )
}

export default EditCamperModal;