import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';


import Login from './components/auth/Login';
import ForgotPassword from './components/auth/ForgotPassword';
import Home from './components/home/Home';
import Success from './components/auth/Success';
import Sidebar from './components/templates/Sidebar';
import Categories from './components/category/Categories';
import Category from './components/category/Category';
import Header from './components/templates/Header';
import Modals from './components/modals';
import Inbox from './components/inbox/Inbox';
import Bookings from './components/booking/Bookings';
import CreateBookingPage from './components/booking/CreateBookingPage';
import Camps from './components/camp/Camps';
import Camp from './components/camp/Camp';
import Offers from './components/offers/Offers';
import Booking from './components/booking/Booking';
import ResetPassword from './components/auth/ResetPassword';
import ScrollToTop from './components/reuse/ScrollToTop';
import Rewards from './components/rewards/rewards';
import FeedRegulation from './components/feedRegulation/FeedRegulation';
import AdditionalSettings from './components/additionalSettings/AdditionalSettings';
import Support from './components/support/support'
import ReviewRegulation from './components/reviewRegulation/ReviewRegulation'
import User from './components/user/User';
import Notification from './components/notification/Notification'
import Payments from './components/payment/Payments'
import Pages from './components/pages/Pages'
import AboutUs from './components/pages/AboutUs'
import Faq from './components/faq/Faq'
import Advertising from './components/advertising/Advertising'
import Setting from './components/setting/Setting'
import ViewPorgram from './components/camp/ViewPorgram';
import Donation from "./components/donation/Donation";
import ErrorBoundary from './ErrorBoundary';
import News from './components/pages/News';
import Career from './components/pages/Career';
import ListCamp from './components/pages/ListCamp';
import Districts from './components/districts/Districts';
import UserDetail from './components/user/detail/UserDetail';
import OnlineStore from './components/online-store';
import ProductBookings from './components/product-bookings/ProductBookings';



const PrivateRoute = ({ component: Component, loggedIn,  ...rest }) => <Route {...rest} render={(props) => loggedIn ? <Component {...props} /> : <Redirect to='/login' />} />

const PublicRoute = ({ component: Component, loggedIn, ...rest }) => <Route {...rest} render={(props) => !loggedIn ? <Component {...props} /> : <Redirect to='/' />} />




const App = props => (
  <div className="site_wrapper">  
    
      <ScrollToTop />
      {props.loggedIn ? <React.Fragment><Header /><Sidebar /></React.Fragment> : ''}
      <ErrorBoundary>
      <Switch>
        <PublicRoute exact path='/login'                             {...props} component={ Login }  />   
        <PublicRoute exact path='/forgot-password'                   {...props} component={ ForgotPassword } />       
        <PublicRoute exact path='/reset-password/:token'             {...props} component={ ResetPassword } />       
        <PublicRoute exact path='/success'                           {...props} component={ Success } />       
        <PrivateRoute exact path='/category'                         {...props} component={ Categories } />       
        <PrivateRoute exact path='/category/:id'                     {...props} component={ Category } />       
        <PrivateRoute exact path='/inbox'                            {...props} component={ Inbox } />   
        <PrivateRoute exact path='/product-bookings'                 {...props} component={ ProductBookings } />   
        <PrivateRoute exact path='/bookings'                         {...props} component={ Bookings } />   
        <PrivateRoute exact path='/bookings/create'                  {...props} component={ CreateBookingPage } />   
        <PrivateRoute exact path='/bookings/detail/:id'              {...props} component={ Booking } />   
        <PrivateRoute exact path='/camps'                            {...props} component={ Camps } />   
        <PrivateRoute exact path='/camps/:id'                        {...props} component={ Camp } />
        <PrivateRoute exact path='/camps/:id/program/:programId'     {...props} component={ ViewPorgram } />
        <PrivateRoute exact path='/offers'                           {...props} component={ Offers } />
        <PrivateRoute exact path='/rewards'                          {...props} component={ Rewards } />
        <PrivateRoute exact path='/feed-regulation'                  {...props} component={ FeedRegulation } />
        <PrivateRoute exact path='/support'                          {...props} component={ Support } />
        <PrivateRoute exact path='/review-regulation'                {...props} component={ ReviewRegulation } />
        <PrivateRoute exact path='/user'                             {...props} component={ User } />
        <PrivateRoute exact path='/user/detail/:id'                  {...props} component={ UserDetail } />
        <PrivateRoute exact path='/notification'                     {...props} component={ Notification } />
        <PrivateRoute exact path='/payments'                         {...props} component={ Payments } />
        <PrivateRoute exact path='/pages'                            {...props} component={ Pages } />
        <PrivateRoute exact path='/pages/news'                       {...props} component={ News } />
        <PrivateRoute exact path='/pages/career'                     {...props} component={ Career } />
        <PrivateRoute exact path='/pages/about'                      {...props} component={ AboutUs } />
        <PrivateRoute exact path='/pages/list-your-camp'             {...props} component={ ListCamp } />
        <PrivateRoute exact path='/faq'                              {...props} component={ Faq } />
        <PrivateRoute exact path='/advertising'                      {...props} component={ Advertising } />
        <PrivateRoute exact path='/additional-settings'              {...props} component={ AdditionalSettings } />
        <PrivateRoute exact path='/setting'                          {...props} component={ Setting } />
        <PrivateRoute exact path='/donation'                         {...props} component={ Donation } />
        <PrivateRoute exact path='/online-store'                     {...props} component={ OnlineStore } />
        <PrivateRoute exact path='/districts'                        {...props} component={ Districts } />
        <PrivateRoute exact path='/'                                 {...props} component={ Home } />
      </Switch>
      <Modals />
    </ErrorBoundary>
  </div>
)

const mapStateToProps = state => {
  let { loggedIn } = state.auth;
  return {
    state,
    loggedIn
  }
}

export default connect(mapStateToProps)(App);