import ApiInstance from './intercepter';
import API from '../config/api';

export const campListWithProgram = () => ApiInstance.get(API.CAMP_LIST_WITH_PROGRAM);

export const campList = params => ApiInstance.post(API.CAMP_LIST + params);

export const addCamp = params => ApiInstance.post(API.CAMP_ADD, params);

export const editCamp = params => ApiInstance.post(API.CAMP_EDIT, params);

export const campDetail = params => ApiInstance.get(API.CAMP_DETAIL + params);

export const campStatusChange = params => ApiInstance.put(API.CAMP_STATUS_CHANGE, params);

export const createProgram = params => ApiInstance.post(API.CREATE_PROGRAM, params);

export const editProgram = params => ApiInstance.post(API.EDIT_PROGRAM, params);

export const viewProgram = params => ApiInstance.get(API.VIEW_PROGRAM + params);

export const updateProgramImg = params => ApiInstance.post(API.UPDATE_PROGRAM_IMG, params);

export const changeProgramStatus = params => ApiInstance.post(API.PROGRAM_STATUS_CHANGE, params);

export const programDelete = params => ApiInstance.delete(API.PROGRAM_DELETE, {data: params});

export const directorDelete = params => ApiInstance.delete(API.DIRECTOR_DELETE, {data: params});

export const updateAdditionalExp = params => ApiInstance.post(API.UPDATE_ADDITIONAL_EXP, params);

export const updateProgramImagesService = params => ApiInstance.post(API.UPDATE_PROGRAM_IMAGES, params);

export const updateMediaService = params => ApiInstance.post(API.UPLOAD_MEDIA, params);

export const uploadCampInvoiceService = params => ApiInstance.post(API.UPLOAD_CAMP_INVOICE, params);

export const deleteCampInvoiceService = params => ApiInstance.delete(API.DELETE_CAMP_INVOICE + params);

export const cloneProgramService = params => ApiInstance.post(API.CLONE_PROGRAM, params);

export const campInvoiceListService = params => ApiInstance.get(API.CAMP_INVOICE_LIST + params);

export const copyProgramItemsService = params => ApiInstance.post(API.COPY_PROGRAM_ITEMS, params);

export const personnalByCampCodeService = params => ApiInstance.get(API.PERSONNEL_BY_CAMP_CODE + params);

export const uploadExcelService = params => ApiInstance.post(API.UPLOAD_EXCEL, params);

export const getDistrictsService = () => ApiInstance.get(API.DISTRICT_LIST);

export const createDistrictService = params => ApiInstance.post(API.DISTRICT_CREATE, params);

export const editDistrictService = params => ApiInstance.post(API.DISTRICT_EDIT, params);

export const getProgramsService = params => ApiInstance.get(API.GET_PROGRAMS_LIST + params);