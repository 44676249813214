import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';

import { setBreadcrumbAction } from '../../store/ui';
import { bookingDetailService, getAdditionalExperienceService, changeSplitStatusService } from '../../services/bookingService';
import { MainLoader, PDFIcon } from '../reuse/SVG';
import { getSignature, getHtml, _calculateAge } from '../../helper';


const getProgramPriceByDates = (_campers, campRegistrationFee) => {
    let _campRegistrationFee = 0;
    for (let i = 0; i < _campers.length; i++) {
        _campRegistrationFee = _campRegistrationFee + _campers[i][campRegistrationFee];
      }
    return _campRegistrationFee;

}

const getAnswers = _data => {
    if(_data.length){
        return _data.map((item, i) => (
            <p key={item._id}>
                {console.log(item, 'itemitemitem')}
                <p  className="color_red">Question {i + 1}: {item.title}</p>

                {
                    (item?.questionType == 3 || item?.questionType == 4) ? <p>Answer: 
                        {
                            Array.isArray(item?.answer) ? item.answer.map(anse => (<span className='ans_chip'>{anse}</span>)) : item?.answer || ' N/A'
                        }
                    </p> : <p className='color_black'>Answer: {item.answer == undefined ? 'N/A' : item.answer}</p>
                }

                
            </p>
        ))

    }else{
        return ''
    }
    


}



const Booking = props => {
    const [bookingDetail, setBookingDetail] = useState([]);
    const [loader, setLoader] = useState(true);
    const [additionalExperience, setAdditionalExperience] = useState([]);
    let params = {
        icon: 'bookings',
        links: [
            {name: 'Bookings', path: '/bookings'}
        ]

    }
    useEffect(() => {
        props.setBreadcrumbAction(params)
    }, []);


    const getBookingDetail = () => {
        let params = {
            bookingId: props.match.params.id
        }

        bookingDetailService(params).then(res => {
            if(res.data.statusCode){
                setBookingDetail(res.data.responseData.result);
                setLoader(false);
            }else{
                setLoader(false);
            }
        }).catch(err => {
            setLoader(false);
        })
    }


    useEffect(() => {
        getBookingDetail()
    }, [])


    const removeStr = s => {
        if(s){
            if(s.includes(',')){
                return s.replace('[', '').replace(']', '').replace(/["]/g, "").replace(/[,]/g, ", ")
            }else{
                return s.replace(/["]/g, "")
            }
        }
    }



    const changeSplitStatus = () => {
        let params = {
            bookingId: props.match.params.id
        }

        changeSplitStatusService(params).then(res => {
            if(res.data.statusCode){
                getBookingDetail();
            }else{
                setLoader(false);
            }
        }).catch(err => {
            setLoader(false);
        })
    }

    return(
        <div className="page_wrapper">
        {loader ? <MainLoader /> : ''}

            <div className="page_card">

                <div className="d-flex align-items-center justify-content-between page_card_header">
                    <h4>Bookings</h4>
                </div>

                <div className="page_card_body">
                <Tabs>
                    {
                     bookingDetail.map(data => (
                        <Tab key={data.programId} eventKey={data.programId} title={data.programName}>

                        



                    <div className="info_sec mt-4">
                        <div className="info_sec_bao_img">
                            {data.is_payment_split == 1 ? <div className="is_payment_split">Half Payment Due <span className='payment_check'><input type='checkbox' onChange={changeSplitStatus} /> <span /></span></div> : ''}
                            <img src={data.userDetails.profileImage ? data.userDetails.profileImage : require('../../assets/img/user.png')} />
                     <h3>{data.userDetails.UserType ? data.userDetails.name + ' ' + data?.userDetails?.lastName : 'Guest User'}</h3>
                            <p>User Code: {data.userDetails.userCode} </p>
                        </div>
                        <div className="info_sec_bao">
                            <p><b>Date of Birth</b> {data.userDetails.dob ? moment.utc(parseInt(data.userDetails.dob)).format('MM/DD/YYYY') : 'N/A'}</p>
                            <p><b>Gender</b> {data.userDetails.gender == '' ? 'N/A' : ''}
                                            {data.userDetails.gender == 1 ? 'Male' : ''}
                                            {data.userDetails.gender == 2 ? 'Female' : ''}
                                            {data.userDetails.gender == 3 ? 'Co-Ed' : ''}</p>
                            <p><b>Primary Phone Number</b> {data.userDetails.phone ? data.userDetails.phone : 'N/A'}</p>
                            <p><b>Secondary Phone Number</b> {data.userDetails.secondaryPhoneNumber ? data.userDetails.secondaryPhoneNumber : 'N/A'}</p>
                            <p><b>Secondary Contact Name</b> {data.userDetails.secondaryContact ? data.userDetails.secondaryContact : 'N/A'}</p>
                            <p><b>Email</b> {data.userDetails.email ? data.userDetails.email : 'N/A'}</p>
                            <p><b>Address</b> {data.userDetails.address ? data.userDetails.address : 'N/A'}</p>
                            
                        </div>
                    </div>




                    {/* <div className="info_sec">
                        <h4>Additional Authorized Pickup :</h4>

                        <div className="row">
                            <div className="col-lg-6">
                                <p><b>Name</b>  ----</p>
                                <p><b>Phone Number</b>  ---- </p>
                                <p><b>Relationship to Camper</b>  ----</p>
                            </div>
                            <div className="col-lg-6">
                                <p><b>Address</b>  ----</p>
                                <p><b>Email</b>  ----</p>
                            </div>
                        </div>
                    </div> */}




                    <div className="info_sec">
                        <h4>Guardian Information :</h4>

                        <div className="row">
                            <div className="col-lg-6">
                                {/* <p><b>First Name:</b> {data?.guardianInformation?.firstName}</p>
                                <p><b>Last Name:</b> {data?.guardianInformation?.lastName}</p> */}
                                <p><b>email:</b> {data?.guardianInformation?.email}</p>
                                <p><b>Primary Contact:</b> {data?.guardianInformation?.primaryContact}</p>
                                <p><b>Primary Phone:</b> {data?.guardianInformation?.primaryPhone}</p>
                                <p><b>Secondary Email:</b> {data?.guardianInformation?.secondaryEmail}</p>
                                
                            </div>
                            <div className="col-lg-6">
                                <p><b>Secondary Contact:</b> {data?.guardianInformation?.secondaryContact}</p>
                                <p><b>Secondary Phone:</b> {data?.guardianInformation?.secondaryPhone}</p>
                                <p><b>Signature Line:</b> {getSignature(data?.guardianInformation?.signatureLine)}</p>
                                <p><b>Signature Text:</b> { data?.guardianInformation?.signatureImage ? <img src={data?.guardianInformation?.signatureImage} /> : ''}</p>
                                
                            </div>
                            
                        </div>
                    </div>



                    {
                        data.campers.map((item, i) => (
                            <div className="info_sec" key={item._id}>
                                <h4>Camper {i + 1} :</h4>

                                <div className="row">
                                    {item?.camperDetail?.firstName ? <Fragment>
                                        <div className="col-lg-6">
                                        <p><b>Child Name</b> {item.camperDetail.firstName} {item.camperDetail.lastName}</p>
                                        <p><b>Date of Birth</b>{moment.utc(item.camperDetail.dob).format('MM/DD/YYYY')}</p>
                                        <p><b>Age</b>{item?.camperDetail?.dob ? _calculateAge(item.camperDetail.dob) : ''}</p>
                                        <p><b>Gender</b> {item.camperDetail.gender == 1 ? 'Male' : ''}
                                            {item.camperDetail.gender == 2 ? 'Female' : ''}
                                            {item.camperDetail.gender == 3 ? 'Co-Ed' : ''}</p>

                                        <p><b>Activity Interest :</b> {item?.camperDetail?.activityInterest ? props.category.filter(el => item?.camperDetail?.activityInterest.find(i => el._id === i.category) && el).map(n => n.name).join(', ') : ''}</p>
                                        
                                        <p><b>T-shirt Size</b> {item.camperDetail.tshirtSize}</p>
                                        <p><b>Current Grade</b> {item.camperDetail.currentGrade}</p>
                                        <p><b>Short Size</b> {item.camperDetail.shortsSize}</p>
                                        <p><b>Dietary Restrictions</b> {removeStr(item.camperDetail.dietaryGuidelines)}</p>
                                    

                                        <p><b>Address</b> {item.camperDetail.address}</p>
                                        <p><b>Apartment</b> {item.camperDetail.apartment}</p>
                                        {item?.camperDetail?.waiver ? <p className="with_pdf"><b>TCL Waiver:</b> <a href={item.camperDetail.waiver} target="_blank"><PDFIcon /></a></p> : ''}
                                        {item?.camperDetail?.waiverLiability ? <p className="with_pdf"><b>Waiver of Liability for COVID-19 Student Sport or Activity:</b> <a href={item.camperDetail.waiverLiability} target="_blank"><PDFIcon /></a></p> : ''}
                                        
                                        

                                    
                                       
                                    </div>
                                    <div className="col-lg-6">
                                        <p><b>Diet is Religious</b> {item.camperDetail.dietReligious == 1 ? 'Yes' : 'No'}</p>
                                        <p><b>Allergy</b> {removeStr(item.camperDetail.allergy)}</p>
                                        <p><b>Allergy is deadly</b> {item.camperDetail.allergyDeadly == 1 ? 'Yes' : 'No'}</p>
                                        <p><b>Medical Condition</b> {removeStr(item.camperDetail.medical)}</p>
                                        <p><b>Video/Photography</b> {item.camperDetail.allowDocumentCamper == 1 ? 'Yes' : 'No'}</p>
                                        <p className="d-flex"><b>Medication, Dosage<br />and Special Assistance</b> {item.camperDetail.medicationsTaken}</p>

                                        <p><b>Participant Bringing<br />Own Medication</b> {item.camperDetail?.participantBringingOwnMedication == 1 ? 'Yes' : 'No'}</p>
                                        <p><b>Participant Receive<br />Counter Medication</b> {item.camperDetail.participantReceiveCounterMedication == 1 ? 'Yes' : 'No'}</p>
                                        <p><b>Permission<br />Emergency Medication</b> {item.camperDetail.permissionEmergencyMedication == 1 ? 'Yes' : 'No'}</p>


                                        <p><b>City</b> {item.camperDetail.city}</p>
                                        <p><b>State</b> {item.camperDetail.state}</p>
                                        <p><b>Zipcode</b> {item.camperDetail.zipCode}</p>
                                        
                                        
                                        
                                    </div>
                                    </Fragment> : <div className='col-lg-12'><h2>Self</h2></div>}
                                    

                                    <div className="col-lg-12 doc_view">
                                        {item?.camperDetail?.hippaDocument ? <p><b>HIPPA Document:</b> <a href={item.camperDetail.hippaDocument} target="_blank"><PDFIcon /></a></p> : ''}
                                        {item?.camperDetail?.medicalDocument ? <p><b>Medical Document:</b> <a href={item.camperDetail.medicalDocument} target="_blank"><PDFIcon /></a></p> : ''}
                                        {item?.gardianPdf ? <p><b>Guardian PDF:</b> <a href={item.gardianPdf} target="_blank"><PDFIcon /></a></p> : ''}
                                        
                                    </div>
                                </div>

                                <h5>Insurance Information :</h5>

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p><b>Medical Insurance Company</b> {item.camperDetail.medicalInsuranceCompany}</p>
                                            <p><b>Policy Number</b> {item.camperDetail.policyNumber}</p>
                                            <p><b>Group #</b> {item.camperDetail.group}</p>
                                        </div>
                                        <div className="col-lg-6">
                                            <p><b>Primary Physician</b> {item.camperDetail.primaryPhysician}</p>
                                            <p><b>Physician Address</b> {item.camperDetail.physicianAddress}</p>
                                            <p><b>Physician Phone #</b> {item.camperDetail.physicianPhone}</p>
                                        </div>
                                    </div>


                                    {
                                item?.camperDetail?.uslCustomerEntity?.uslCustomerEntity?.length ? <Fragment>
                                    <h5>USL Customer Information -</h5>

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <p><span className="color_black">First Name :</span> {item?.camperDetail?.uslCustomerEntity?.uslCustomerEntity[0]?.FirstName}</p>
                                            <p><span className="color_black">Last Name :</span> {item?.camperDetail?.uslCustomerEntity?.uslCustomerEntity[0]?.LastName}</p>
                                            <p><span className="color_black">USL Member ID :</span> {item?.camperDetail?.uslCustomerEntity?.uslCustomerEntity[0]?.USLMemberID}</p>
                                            <p><span className="color_black">Expiration Date :</span> {item?.camperDetail?.uslCustomerEntity?.uslCustomerEntity[0]?.ExpirationDate}</p>
                                            <p><span className="color_black">Email :</span> {item?.camperDetail?.uslCustomerEntity?.uslCustomerEntity[0]?.Email}</p>
                                        </div>
                                    </div>

                                </Fragment> : ''
                            }


                                
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {item.additionalExperience.length ? <h4 className="color_red">Additional Experience -</h4> : ''}
                                            {
                                                item.additionalExperience.filter(j => j.numberOfBooking > 0).map(addit => <div className="cm_addit">
                                                <p><b>Title</b></p>
                                                <p>{getHtml(addit.title)}</p>
                                                <p><b>Number of booking:</b> {addit.numberOfBooking}</p>
                                                <p><b>price:</b> ${parseInt(addit.price).toFixed(2)}</p>

                                                {
                                                    addit?.answer?.title ? <Fragment>
                                                        <p className='text-danger'><b>Question:</b> {addit.answer.title}</p>
                                                        <p><b>Answer:</b> {addit.answer.answer}</p>
                                                    </Fragment> : ''
                                                }
                                                
                                    
                                            </div>)
                                            }
                                        </div>
                                    </div>


                                    {getAnswers(item?.answers)}

                            </div>
                        ))
                    }


                    <div className="info_sec">
                        <h4>Program & Price  Information:</h4>

                        <div className="row">
                            <div className="col-lg-6">
                                <p><b>Camp Name</b> {data.campName}</p>
                                <p><b>Camp Program Name</b> {data.programName}</p>
                                <p><b>Program Code</b>  {data.programCode}</p>
                                <p><b>Booking Date</b> {moment(data.bookingDate).format('MM/DD/YYYY')}</p>
                                <p><b>Date of Program</b> {moment.utc(data.startDate).format('MM/DD/YYYY')}</p>
                                <p><b>Program Duration</b>  ----</p>
                                {/* <p><b>Program Duration</b>{moment(data.endDate - data.startDate).format('DD/MM/YYYY')}</p> */}
                                <p><b>Coupon Code Applied</b>  {data?.couponCode ? data?.couponCode : 'No'}</p>
                                <p><b>Payment Type</b>  {data.paymentDetail.method}</p>
                                <p><b>transaction Id</b>  {data.paymentDetail.transaction_id}</p>

                            </div>
                            <div className="col-lg-6">
                                <p><b>Gift Card Applied</b> ----</p>

                                <p><b>Program Fee</b> ${data.byDates == 1 ? getProgramPriceByDates(data.campers, 'campRegistrationFee') / data.campers.length : data.campers[0].campRegistrationFee}</p>
                                <p><b>Convenience Fee <small>(each participant)</small></b> ${data.convenienceFee}</p>
                                <p><b>Non-refundable Fee <small>(each participant)</small></b> ${data.registerNonRefundFee}</p>
                                <p><b>Insurance Fee</b> ${data.insurance || 0}</p>
                                
                                <hr />
                                <p><b>Booking Fee <small>({data.campers.length} participants)</small></b> ${data.byDates == 1 ? getProgramPriceByDates(data.campers, 'programCost') : (data.campers[0].programCost * data.campers.length)}</p>
                                <p><b>Coupon Price</b> - ${data.couponValue || 0}</p>
                                {/* <p><b>Total Fee</b> ${(data.registrationFee + data.convenienceFee + data.registerNonRefundFee) * data.campers.length}</p> */}
                                {/* <p><b>Total Fee</b> ${data.byDates == 1 ? getProgramPriceByDates(data.campers, 'programCost') - (data.couponValue || 0) : (data.campers[0].programCost * data.campers.length) - (data.couponValue || 0)}</p> */}
                                <p><b>Total Fee</b> ${(data?.paymentDetail?.amount).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                    




        





                        </Tab>
                     ))   
                    }
                </Tabs>
                </div>

            </div>


            <Link to='/bookings' className="btn btn-outline-primary mt-4 mb-3"><i className="icon-left-arrow" /> GO Back</Link>


        </div>
    )
}


const mapStateToProps = state => {
    let { preList } = state.ui;
    let { category, subcategory } = preList;
    return {
        category,
        subcategories: subcategory,
        state
    };
}
  
const mapDispatchToProps = dispatch => ({
    setBreadcrumbAction: params => dispatch(setBreadcrumbAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Booking);

